export const suppressPhrases = [
  'chrome-extension://',
  'Network Error',
  'Request Aborted',
  'auctionsWithNewBids',
]

export const beforeSend = (event) => {
  if (event.exception && event.exception.values && event.exception.values.length > 0) {
    for (let i = 0; i < event.exception.values.length; i++) {
      const value = event.exception.values[i]

      for (let j = 0; j < suppressPhrases.length; j++) {
        const phrase = suppressPhrases[j]

        if (value.value?.includes(phrase)) {
          return null
        }

        if (value.type?.includes(phrase)) {
          return null
        }
      }
    }
  }

  return event
}

export const beforeSendSpan = (span) => {
  // temporarily disable spans
  return null
}

export default {
  // Define all sentry configuration here that both vue and blade contexts
  // should share
  tracesSampleRate: 0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  beforeSend,
  beforeSendSpan,
  allowUrls: [
    /https:\/\/liveplatforms-[^.]+\.b-cdn\.net\//
  ],
}
