import { defineAsyncComponent } from 'vue'

export default {
  computed: {
    modalComponent() {
      return this.$page.props.modal
        ? defineAsyncComponent(() => import(`../Modals/${this.$page.props.modal}.vue`))
        : false
    }
  }
}
